@import './_color-variables.scss';
@import './_mixins.scss';

.landing-layout {
  :global {
    .content-container {
      max-width: 100% !important;
      padding: 0;
    }
  }
}

@keyframes aniDropDown {
  0% {
    transform: translateY(18px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(18px);
  }
}

.landingpage {
  .introduction {
    margin: 0 auto;

    &__sub-text {
      font-weight: 400;
      font-size: 28px;
      text-align: center;
      color: $paragraph1;
      max-width: 744px;
      margin: 0 auto;

      @include tablet {
        font-size: 24px;
      }
    }

    &__main-text {
      font-weight: 700;
      font-size: 45px;
      line-height: 55px;
      text-align: center;
      color: $paragraph1;
      margin: 0 auto;
      letter-spacing: -0.015em;
      font-family: 'DM Sans', sans-serif;

      span {
        color: $brand-main;
      }

      @include tablet {
        font-size: 32px;
        line-height: 40px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    &__description {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: left;
      color: $paragraph1;
      max-width: 450px;
      margin: 25px 0 0;
    }

    &__btn {
      padding-top: 50px;
    }
  }

  .feedback {
    padding-top: 80px;
    padding-bottom: 120px;
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    @include tablet {
      padding-bottom: 160px;
      padding-top: 75px;
    }
    &__content-width {
      max-width: 1120px;
      padding: 0 15px;
      margin: 0 auto;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: $bg-2;
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 250px 250px 0 0;
      @include tablet {
        border-radius: 100px 100px 0 0;
      }
    }

    &__heading {
      font-size: 45px;
      text-align: center;
      color: $paragraph1;
      margin: 0 auto 45px;
      letter-spacing: -0.015em;
      max-width: 750px;
      line-height: 1;
      font-family: 'DM Sans Bold', sans-serif;
      img {
        margin: 0 auto 30px;
        @include tablet {
          max-width: 45px;
        }
      }

      span {
        color: $brand-main;
      }

      @include tablet {
        font-size: 32px;
        margin-bottom: 40px;
        br {
          display: none;
        }
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include tablet {
        flex-wrap: nowrap;
        overflow-x: auto;
        width: max-content;
      }
    }

    &__content {
      @include tablet {
        overflow-x: auto;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
      }
    }

    &__description {
      min-height: 134px;
    }

    &__item {
      background: $title;
      backdrop-filter: blur(8px);
      border-radius: 10px;
      padding: 40px 35px;
      max-width: 100%;
      font-size: 16px;
      line-height: 160%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      color: $bg1;
      text-align: left;
      min-height: 316px;
      font-family: 'DM Sans Regular', sans-serif;
      @include tablet {
        width: 100%;
      }
    }

    &__name {
      margin: 24px 0 8px;
      font-size: 18px;
      font-family: 'DM Sans Bold', sans-serif;
    }

    &__title {
      color: $brand-main;
      font-family: 'DM Sans Regular', sans-serif;
      font-size: 14px;
    }

    :global {
      .swiper-pagination {
        margin-top: 30px;
        height: 40px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        top: inherit;
        transform: translate(0, 0);
        width: 40px;
        height: 40px;
        bottom: 0px;
        background-image: url(/icons/icon-next-btn.svg);
        background-size: 14px;
        border-radius: 50%;
        border: 1px solid $bg1;
        right: 60px;
      }

      .swiper-button-prev {
        background-image: url(/icons/icon-prev-btn.svg);
        right: inherit;
        left: 60px;
      }

      @include tablet {
        .swiper-pagination-bullet {
          display: inline-block;
          width: 5px;
          height: 5px;
          margin: 18px 7px 0;
          background: $bg1;
          border-radius: 10px;
          opacity: 0.12;
          &-active {
            opacity: 1;
          }
        }
      }
    }
  }

  .get-start {
    &__heading {
      font-weight: 400;
      font-size: 80px;
      line-height: 120%;
      text-align: center;
      color: $title;
      margin-bottom: 60px;
      font-family: 'Spline Sans Mono', sans-serif;

      span {
        color: $link;
      }

      @include tablet {
        font-size: 48px;
        margin-bottom: 40px;
      }
      max-width: 700px;
    }

    &__description {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: left;
      color: $paragraph1;
      max-width: 450px;
      margin: 25px 0 0;
    }

    &__btn {
      padding-top: 50px;
    }
  }

  .get-start {
    background: $bg-2;
    padding: 122px 0 25px;
    border-radius: 0 0 250px 250px;
    @include tablet {
      border-radius: 0 0 100px 100px;
    }
    &__title {
      text-align: left;
      font-weight: 700;
      font-size: 58px;
      line-height: 68px;
      color: $paragraph1;
      margin: 0 auto;
      letter-spacing: -0.015em;
      font-family: 'DM Sans', sans-serif;

      span {
        color: $brand-main;
      }
      max-width: 700px;

      @include tablet {
        font-size: 40px;
        line-height: 54px;
      }
    }
    &--wrapper {
      display: flex;
      max-width: 1240px;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;
      @include pc {
        justify-content: center;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    &--left {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &--right {
      max-width: 100%;
      &-img {
        width: 100%;
      }
    }
    &--scroll {
      text-align: center;
      font-family: 'DM Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      color: $brand-main;

      &-img {
        animation: aniDropDown 2s ease infinite;
      }
    }
  }
  .available-progress {
    padding: 100px 0;
    max-width: 1240px;
    margin: 0 auto;
    @include tablet {
      padding: 70px 0;
    }
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 50px;
      @include laptop {
        flex-direction: column;
        padding-top: 30px;
      }
      &--item {
        &:first-child,
        &:last-child {
          padding: 0 20px;
        }
      }
      &--arrow {
        height: 200px;
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-top: 40px;
        }
        @include laptop {
          transform: rotate(90deg);
          width: 80px;
          height: 110px;
          img {
            margin: 0;
          }
        }
      }

      p {
        text-align: center;
        max-width: 207px;
        padding-top: 18px;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $paragraph1;
      }
    }
  }
  .blockchain-network {
    padding: 0px 0 210px;
    &__content {
      padding-top: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      @include tablet {
        padding: 30px 10px 0;
      }
    }
    @include tablet {
      padding: 0 0 180px;
    }
    .hexagon-network {
      width: 120px;
      height: 135px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile {
        width: 74px;
        height: 82px;
      }
      &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('/icons/icon-hexagon.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      &__logo {
        @include mobile {
          width: 50%;
        }
      }
      &__label {
        position: absolute;
        width: 100%;
      }
    }
    &__available {
      display: inline-flex;

      gap: 40px;
      justify-content: center;
      padding-bottom: 20px;
      margin: 0 auto;
      @include tablet {
        gap: 20px;
        padding-bottom: 0;
      }
      @include mobile {
        gap: 12px;
      }
    }
    &__coming-soon {
      margin: 0 auto;
      display: inline-flex;
      gap: 20px 40px;
      justify-content: center;
      flex-wrap: wrap;

      @include laptop {
        width: 500px;
      }
      @include tablet {
        gap: 10px 20px;
      }
      @include mobile {
        width: 300px;
        gap: 6px 12px;
      }

      .hexagon-network {
        &__bg {
          opacity: 0.2;
        }
        &__logo {
          opacity: 0.2;
        }
      }
    }
  }

  .btn-primary {
    background: $brand-gradient;
    border-radius: 80px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    padding: 13px 66px;
    color: $title;
    font-family: 'Anderson Grotesk Bold', sans-serif;
    border: transparent;
    height: 65px;
    &:hover {
      cursor: pointer;
      background: $brand-main;
    }
  }

  color: $paragraph1;
}
// last-update 17-05-2023
.gif-rubik {
  object-fit: cover;
  z-index: 2;
}
.text-scroll-down {
  color: $brand-main;
  font-family: 'DM Sans Medium';
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}
.img-down-anim {
  animation: aniDropDown 2s ease infinite;
}

.btn-start-free {
  width: 240px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $brand-gradient;
  border-radius: 32.5px;
  color: white;

  @include bold-h6;

  &:hover {
    background: $brand-main;
  }
}

.title-started {
  color: $paragraph1;
  letter-spacing: -0.015em;
  text-transform: capitalize;
  @include bold-h1;
  & > span {
    color: $brand-main;
  }
  @media (max-width: 992px) {
    @include bold-h3;
  }
}
.subtext-started {
  color: $paragraph1;
  opacity: 0.8;
  @include regular-h6;
}

.item-product:last-child {
  margin-bottom: 53px;
}

.heading-text {
  color: $paragraph1;
  text-align: center;
  margin: 0 auto;
  @include bold-h2;
  & > span {
    color: $brand-main;
  }
  @media (max-width: 992px) {
    @include bold-h4;
  }
}

.caption-text {
  color: $paragraph1;
  text-align: center;
  @include bold-h8;
}

@keyframes block-anim {
  $distance: 50px;
  0% {
    transform: translate(-$distance / 2, -$distance / 2);
  }
  25% {
    transform: translate($distance / 2, -$distance / 2);
  }
  50% {
    transform: translate($distance / 2, $distance / 2);
  }
  75% {
    transform: translate(-$distance / 2, $distance / 2);
  }
  100% {
    transform: translate(-$distance / 2, -$distance / 2);
  }
}
.block-rubik {
  position: absolute;
  z-index: 1;
  animation: block-anim 4s linear infinite;
  animation-duration: var(--block-duration);
  &:nth-of-type(1) {
    top: 30%;
    left: 0%;

    @media (max-width: 992px) {
      display: none;
    }
  }
  &:nth-of-type(2) {
    top: 40%;
    left: 95%;
  }
  &:nth-of-type(3) {
    top: 70%;
    left: 45%;

    @media (max-width: 992px) {
      display: none;
    }
  }
  &:nth-of-type(4) {
    top: 15%;
    left: 45%;
  }
}

.circle-img {
  position: absolute;
  z-index: 0;
  &.circle-01 {
    top: 62%;
    left: 54%;
    @media (max-width: 992px) {
      display: none;
    }
  }
  &.circle-02 {
    top: 10%;
    left: 20%;

    @media (max-width: 992px) {
      top: 35%;
      left: 43%;
    }
  }
}
.title-limit {
  font-size: 24px;
  font-family: 'DM Sans Medium';
  margin-bottom: 10px;
}

.content-limit {
  font-family: 'DM Sans Regular';
  font-size: 20px;
  margin-bottom: 11px;
  color: $bg1;

  a {
    color: $link2;

    &:hover {
      text-decoration: underline;
    }
  }
}
.hr {
  border-color: black;
  margin-bottom: 66px;
}

.off-start {
  opacity: 0 !important;
}
.on-end {
  opacity: 1 !important;
}

//
